function get_top(element) {
    Rails.ajax({
        url: "/ujs/top_" + element,
        type: "PATCH",
        success: function(data) {
        },
        error: function(error) {
        }
    });
};

async function get_tops() {
    // for (const e of ["ip_addresses", "phone_numbers", "scamalyzer_codes", "email_addresses", "ceacs"]) {
    //     await get_top(e);
    // }
    const promises = [];
    
    for (const e of ["ip_addresses", "phone_numbers", "email_addresses", "ceacs"]) {
         promises.push(get_top(e));
    }
    
    await Promise.all(promises);

    
    // await get_top("ip_addresses");
    // await get_top("phone_numbers");
    // //await get_top("scamalyzer_codes");
    // await get_top("email_addresses");
    // await get_top("ceacs");
    
}

function change_country(event) {
    // this is the way
    Rails.ajax({
        url: "/ujs/change_country",
        type: "PATCH",
        data: "country=" + event.target.value,
        success: function(data) {
            get_tops();
        }
    });
};

document.querySelector("#country_name").addEventListener("change", (event) => {
    //event.preventDefault();
    change_country(event);
});

get_tops();

//ATLP-5485
//document.querySelector("#top_ip_addresses").innerHTML("");
